
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';

import AppProfileMenu from '@widgets/AppProfileMenu.vue';

import * as ProfileService from '@/services/api/profile';

import getFullname from '@/helpers/getFullname';
import getInitials from '@/helpers/getInitials';
import { UUID } from '@/domains/common';
import { pushState } from '@/helpers';

const companyStore = createNamespacedHelpers('company');
const userStore = createNamespacedHelpers('user');

interface MyApp {
  href: string
  testLabel: string
  text: string
}

interface App {
  appName: string
}

interface Action {
  id: string,
  icon: string,
  text: string,
  color: string,
  testValue: string,
}

interface Company {
  id: UUID;
  isAdmin: boolean;
  name: string;
}

interface AppNavData {
  apps: App[],
  actions: Action[],
  companies: Company[],
  company: Company,
}

interface IUserStore {
  raw: {
    teamId: string
  }
}

export default Vue.extend({
  name: 'AppNav',

  components: {
    AppProfileMenu,
  },

  inject: ['Names'],

  data(): AppNavData {
    return {
      apps: [],
      actions: [
        {
          id: 'my-data',
          icon: 'fal fa-clipboard-user',
          text: this.$t('AppNav.myData'),
          testValue: 'my-data',
          color: 'tt-light-mono-100',
        },
        {
          id: 'sign-out',
          icon: '$signOut',
          text: this.$t('AppNav.quit'),
          testValue: 'sign-out',
          color: 'red',
        },
      ],
      companies: [],
      company: {
        id: '',
        isAdmin: false,
        name: '',
      },
    };
  },

  computed: {
    ...companyStore.mapGetters(['companyName', 'companyId']),
    ...userStore.mapGetters(['userId', 'userName', 'userLastName', 'userAvatarUrl', 'hasAdminRole']),
    ...userStore.mapState({ teamId: (state: unknown) => (state as IUserStore).raw.teamId }),
    userHasTeam(): boolean {
      return Boolean(this.teamId);
    },
    currentApp(): string {
      // TODO(onBeforeBuildHook): Ловить отсутствующие переменные при сборке
      return process.env.VUE_APP_LEARNINGBOX_ADDRESS!;
    },
    profileAdminUrl(): string {
      // TODO(onBeforeBuildHook): Ловить отсутствующие переменные при сборке
      return process.env.VUE_APP_PROFILEADMIN_ADDRESS!;
    },
    brandUrl(): string {
      return '/';
    },
    myApps(): MyApp[] {
      const licenses = this.apps.map((i) => i.appName);

      const dict = {
        [process.env.VUE_APP_ARCHIVIST_ADDRESS!]: false,
        [process.env.VUE_APP_GOALS_ADDRESS!]: 'pms',
        [process.env.VUE_APP_LEARNINGBOX_ADDRESS!]: true,
        [process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS!]: 'engagement',
      };

      const apps = [
        {
          text: this.$t('AppNav.menu.archivist'),
          href: process.env.VUE_APP_ARCHIVIST_ADDRESS!,
          testLabel: 'archivist-header-menu',
        },
        {
          text: this.$t('AppNav.menu.goals'),
          href: process.env.VUE_APP_GOALS_ADDRESS!,
          testLabel: 'goals-header-menu',
        },
        {
          text: this.$t('AppNav.menu.learning'),
          href: process.env.VUE_APP_LEARNINGBOX_ADDRESS!,
          testLabel: 'learning-header-menu',
        },
        {
          text: this.$t('AppNav.menu.engagement'),
          href: process.env.VUE_APP_ENGAGEMENTADMIN_ADDRESS!,
          testLabel: 'engagement-header-menu',
        },
      ];

      return apps.filter((app) => {
        const license = dict[app.href];
        // @ts-expect-error
        return license === true || licenses.includes(license);
      });
    },

    myActions(): Action[] {
      const MY_TEAM_ACTION = {
        id: 'my-team',
        icon: 'fal fa-users',
        text: this.$t('AppNav.myTeam'),
        testValue: 'my-team',
        color: 'tt-light-mono-100',
      };

      const actions = [...this.actions];

      if (this.userHasTeam) {
        actions.splice(this.actions.length - 1, 0, MY_TEAM_ACTION);
      }

      return actions;
    },

    currentCompany(): { id: string, name: string } {
      return {
        id: this.companyId,
        name: this.companyName,
      };
    },
    userFullName(): string {
      return getFullname({
        firstname: this.userName,
        lastname: this.userLastName,
      });
    },
    userInitials(): string {
      return getInitials({
        firstname: this.userName,
        lastname: this.userLastName,
      });
    },
    analyzedPages(): string[] {
      return [
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_SCORM_VIEW,
        this.Names.R_APP_LEARNING_ASSIGNMENT_TRACK_STEP_MULTICONTENT,
      ];
    },
  },

  async created() {
    try {
      this.fetchCompanies();

      const apps = await ProfileService.appsGet(); // Note: если 204, то вернётся null
      if (Array.isArray(apps) && apps.length) {
        this.apps = apps;
      } else {
        // IMPORTANT: редиректим в цп, если нет приложений
        const url = new URL(process.env.VUE_APP_ARCHIVIST_ADDRESS!);
        window.location.replace(url.href);
      }
    } catch (error) {
      this.$di.snack.error('Не удалось получить список доступных приложений');
    }
  },

  methods: {
    async handleMyData() {
      const url = new URL('app/me', process.env.VUE_APP_ARCHIVIST_ADDRESS);
      await this.sendAnalytic(this.$t('AppNav.myData'), 'nav-bar-my-data');

      pushState(url);
    },

    async handleMyTeam() {
      const url = new URL('app/team', process.env.VUE_APP_ARCHIVIST_ADDRESS);
      await this.sendAnalytic(this.$t('AppNav.myTeam'), 'nav-bar-my-team');

      pushState(url);
    },

    async handleSignOut() {
      await this.sendAnalytic(this.$t('AppNav.quit'), 'nav-bar-sign-out');

      this.$router.push({
        name: this.Names.R_AUTH_LOGOUT,
        query: {
          back: window.location.href,
        },
      });
    },

    async fetchCompanies() {
      try {
        const { companies } = await ProfileService.profileCompaniesGet();
        this.companies = companies;
        this.company = companies.find((i: Company) => i.id === this.companyId);
      } catch (err: any) {
        this.$di.notify.error({ content: 'Не удалось получить данных о компаниях к которым принадлежит пользователь' });
      }
    },

    async onSelectCompanyHandler(company: Company) {
      if (company.id === this.currentCompany.id) return;

      await this.sendAnalytic(this.$t('AppNav.quit'), 'nav-bar-sign-out');

      this.$router.push({
        name: this.Names.R_AUTH_CHANGE_COMPANY,
        params: {
          companyId: company.id,
        },
      });
    },

    async sendAnalytic(eventValue: string, eventLabel: string) {
      if (!this.analyzedPages.includes(this.$route.name!)) return;

      const data = {
        companyId: this.currentCompany.id,
        playerSessionId: this.$route.params.playerSessionId,
        stepId: this.$route.params.stepId,
        trackSessionId: this.$route.params.trackSessionId,
      };

      await this.$di.tmt.sendEvent('send', 'click', eventValue, eventLabel, 'externalLink', data);
    },

    async onClickLogoLinkHandler() {
      await this.sendAnalytic(this.$t('AppNav.brand'), 'nav-bar-brand');

      pushState(this.brandUrl);
    },

    async onClickAdminLinkHandler() {
      await this.sendAnalytic(this.$t('AppNav.adminLink'), 'nav-bar-admin-link');

      pushState(this.profileAdminUrl);
    },
  },
});

